$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });

    let refs = {
        submenus: selector.find(`[data-ref="submenu"]`)
    };

    (function() {
        let timer_out;
        selector.off("mouseenter mouseleave", `[data-hover="submenu"]`);

        selector.on("mouseenter mouseleave", `[data-hover="submenu"]`, function (e) {
            let elm = $(this);
            let id = elm.data("sub");
            let submenu = $(refs.submenus.filter(`[data-sub="${id}"]`));
            submenu.off("mouseenter mouseleave");
            selector.find(`[data-hover="submenu"]`).not(elm).removeClass("state--hover");

            if (e.type === 'mouseenter') {
                clearTimeout(timer_out);
                submenu.addClass("state--active");
                elm.addClass("state--hover");
                $(refs.submenus.not(`[data-sub="${id}"]`)).each(function () {
                    let other = $(this);
                    console.log(other);
                    if(other.hasClass("state--active")) {
                        other.addClass("state--hiding");
                        setTimeout(function() {
                            other.removeClass("state--active state--hiding");
                        },300);
                    }
                });
            } else if (e.type === 'mouseleave') {
                clearTimeout(timer_out);
                timer_out = setTimeout(function() {
                    submenu.addClass("state--hiding");
                    setTimeout(function() {
                        submenu.removeClass("state--active state--hiding");
                        elm.removeClass("state--hover");
                    },300);
                },500);
            }

            submenu.on("mouseenter mouseleave", function(e) {
                if (e.type === 'mouseenter') {
                    clearTimeout(timer_out);
                } else if (e.type === 'mouseleave') {
                    clearTimeout(timer_out);
                    timer_out = setTimeout(function() {
                        submenu.addClass("state--hiding");
                        setTimeout(function() {
                            submenu.removeClass("state--active state--hiding");
                            elm.removeClass("state--hover");
                        },300);
                    },500);
                }
            });
        });
    })();
});

$.importScript(cdnjs.touchswipe, function() {
    $('.ssm-nav').lui_ssm();
});