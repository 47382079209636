let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.10.3/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "airdatepicker": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/datepicker.min.js",
    "airdatepicker_lang": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/i18n/datepicker.cs.js",
    "daysjs": "https://cdn.jsdelivr.net/npm/dayjs@1.8.17/dayjs.min.js",
    "overlay_scrollbars": "https://cdn.jsdelivr.net/npm/overlayscrollbars@1.10.0/js/OverlayScrollbars.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.12/js/lightgallery-all.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "tinymce": "https://cdn.jsdelivr.net/npm/tinymce@5.1.2/tinymce.min.js",
};