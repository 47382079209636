$.lui("comp",".comp_list",function (selector) {
    selector.on("click",".elm_nav_item",function (e) {
        let elm = $(this);
        elm.closest(".elm_nav_inner").find(".state--active").removeClass("state--active");
        elm.closest(".elm_nav_inner").animate({
            scrollLeft: elm[0].offsetLeft - 48
        },300);
        elm.addClass("state--active");
        /*RUN AJAX*/
    });
});