$.lui("part",".part_form_add",function (selector) {
    let date_from = selector.find("#f_event_from"),
        date_from_alt = date_from.next(`[type="hidden"]`),
        date_to = selector.find("#f_event_to"),
        date_to_alt = date_to.next(`[type="hidden"]`);

    $.importScript(cdnjs.airdatepicker,function () {
        $.importScript(cdnjs.airdatepicker_lang.replace(".cs.",`.${lang}.`), function () {
            $.importScript(cdnjs.daysjs, function () {

                date_from.datepicker({
                    language: lang,
                    minDate: new Date(),
                    altField: date_from_alt,
                    altFieldDateFormat: "yyyy-mm-dd",
                    autoClose: true,
                    onShow: function () {
                        doc.find(".datepickers-container").addClass("state--active");
                    },
                    onHide: function () {
                        doc.find(".datepickers-container").removeClass("state--active");
                    },
                }).data("datepicker").selectDate(new Date());

                date_to.datepicker({
                    language: lang,
                    minDate: new Date(),
                    altField: date_to_alt,
                    altFieldDateFormat: "yyyy-mm-dd",
                    autoClose: true,
                    onShow: function () {
                        doc.find(".datepickers-container").addClass("state--active");
                    },
                    onHide: function () {
                        doc.find(".datepickers-container").removeClass("state--active");
                    },
                }).data("datepicker").selectDate(dayjs().add(1,'day').$d);

                date_from.data("datepicker").update({
                    onSelect: function (fd,d,inst) {
                        date_to.data("datepicker").update('minDate',d);
                        if(dayjs(d).isAfter(dayjs(date_to_alt.val()))) {
                            date_to.data("datepicker").selectDate(dayjs(d).add(1,'day').$d);
                        }
                    },
                })
            });
        });
    })
});