$.lui("comp",".comp_visual_calendar",function (selector) {
    let calendar = selector.find("[data-calendar]");
    if(calendar.length) {
        let cal_data = calendar.data("calendar");

        let event_dates = cal_data.dates.map(function(el) {
            return el.date;
        });

        $.importScript(cdnjs.airdatepicker,function () {
            $.importScript(cdnjs.airdatepicker_lang.replace(".cs.", `.${lang}.`),function () {
                $.importScript(cdnjs.daysjs, function () {
                    calendar.datepicker({
                        inline: true,
                        language: lang,
                        showOtherMonths: false,
                        moveToOtherMonthsOnSelect: false,
                        onRenderCell: function (date, cellType) {
                            let index = event_dates.indexOf(dayjs(date).format("YYYY-MM-DD"));
                            if (cellType === 'day' && index !== -1) {
                                let list = document.createElement('ul');
                                if(cal_data.dates[index].events !== null) {
                                    cal_data.dates[index].events.forEach(function (item) {
                                        let li = document.createElement('li');
                                        list.appendChild(li);
                                        li.innerHTML = `<span>${item.title}</span>`
                                    });
                                }
                                return {
                                    classes: 'has--events',
                                    html: `<a href="${cal_data.dates[index].url}">${date.getDate()}</a><div class="elm_events"><ul>${list.innerHTML}</ul></div>`
                                }
                            }
                        },
                    }).data('datepicker');
                });
            })
        })
    }
});
