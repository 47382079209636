(function($) {
    doc.on("change", ".part_ui_file", function () {
        let elm = $(this),
            input = elm.find("input")[0],
            img = elm.find("img");

        elm.removeClass("state--active");

        if (input.files && input.files[0]) {
            let reader = new FileReader();

            reader.onload = function (e) {
                img.attr('src', e.target.result);
            };

            reader.readAsDataURL(input.files[0]);

            elm.addClass("state--valid");
        }
    });
})(jQuery);