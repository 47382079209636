(function($) {
    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_autosize", "[data-lib-autosize]", function(selector) {
        $.importScript(cdnjs.autosize,function () {
            autosize(selector.find('textarea'));
        });
    });

    $.lui("lib_gallery", "[data-lib-gallery]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".elm_gallery_item").length) {
                $.importScript(cdnjs.lightgallery, function(){
                    $(selector).each(function(){
                        let gallery = $(this).lightGallery({
                            thumbnail: true,
                            selector: ".elm_gallery_item",
                            exThumbImage: 'data-exthumbimage',
                            fullScreen: false,
                            zoom: true,
                            actualSize: false,
                            hash: false,
                            download: true,
                            autoplay: false,
                            autoplayControls: false,
                        });
                        gallery.on('onAfterOpen.lg',function(event){
                            $('#lg-download').attr('data-no-swup', '');
                        });
                    });
                });
            }
        });
    });

    $.lui("lib_tinymce", "[data-lib-mce]", function() {
        $.importScript(cdnjs.tinymce, function(){
            tinymce.remove();

            tinymce.init({
                selector: '[data-lib-mce]',
                language: 'cs',
                language_url: '/assets/tinymce/langs/cs.js',
                menubar: false,
                skin: 'oxide',
                plugins: [
                    'hr advlist autolink lists link image charmap print preview hr anchor pagebreak',
                    'searchreplace wordcount visualblocks visualchars code fullscreen help',
                    'insertdatetime media nonbreaking save table directionality',
                    'emoticons template paste textpattern'
                ],
                toolbar: 'bold italic underline | alignleft aligncenter alignright | bullist numlist | link image media | removeformat | fullscreen',
            });
            html.addClass("tinymce-loaded");
        });
    });

    if (document.documentElement.classList.toString().match(/(android|ios|iphone|ipad)/) === null) {
        $.lui("lib_scrollbars", "[data-lib-scrollbars]", function (selector) {
            $.importScript(cdnjs.overlay_scrollbars, function () {
                selector.each(function () {
                    let el = $(this),
                        opts = el.data("lib-scrollbars");

                    OverlayScrollbars(el[0], {
                        className: (opts.theme === undefined) ? "os-theme-dark" : opts.theme,
                        overflowBehavior: {
                            x: (opts.x === undefined) ? "hidden" : opts.x,
                            y: (opts.y === undefined) ? "scroll" : opts.y
                        },
                        scrollbars: {
                            autoHide: "move",
                            autoHideDelay: 800
                        }
                    });
                })
            });
        });
    }

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    const list = new DOMParser().parseFromString(payload.snippets[Object.keys(payload.snippets)[0]], "text/html").body.children[0];
                    if(list.classList.contains("comp_list")) {
                        $(list).anchor_anim(500)
                    }
                });
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
        selector.lib_reCaptcha("lib-recaptcha");
    });
    setInterval(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    }, 150000);
})(jQuery);
